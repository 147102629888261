<template>
  <div id="certUpdate">
    <header-nav :content="`${$t('pages_router_031')} / ${ $t('pages_router_060')}`" />
    <div class="common-card-body">
      <a-form
        class="cert-update-form"
        layout="vertical"
        :model="form"
        @finish="finish"
        @finishFailed="failed"
      >
        <div
          v-for="(item, index) in form"
          :key="index"
          style="margin-bottom: 24px"
        >
          <template v-if="['06', '04', '03', '05'].includes(item.type)">
            <a-divider orientation="right">{{ getTitle(item.type) }}</a-divider>
            <a-alert
              v-if="item.failReason"
              :message="item.failReason"
              style="margin-bottom: 24px"
              type="error"
              banner
            />
            <a-descriptions :column="2">
              <a-descriptions-item :label="$t('pages_user_022')">{{ item.originName }}</a-descriptions-item>
              <a-descriptions-item :label="$t('pages_user_124')">
                <span>{{ viewMap[index] ? item.originNumber : item.originNumberText }}</span>
                <div
                  v-if="item.originNumber"
                  class="icon-area"
                  @click="icClick(index)"
                >
                  <eye-invisible-outlined v-if="viewMap[index]" />
                  <eye-outlined v-else />
                </div>
              </a-descriptions-item>
              <a-descriptions-item :label="$t('pages_user_125')">{{ item.originIssuanceplace }}</a-descriptions-item>
              <a-descriptions-item :label="$t('pages_user_126')">{{ item.originCustomertypeText }}</a-descriptions-item>
            </a-descriptions>
            <div class="upload-area">
              <a-form-item
                :name="[index, 'frontPath']"
                :label="item.originCustomertype === '0' ? $t('pages_user_127') : $t('pages_user_129')"
                :rules="{ required: true, message: $t('common_text_007') + (item.originCustomertype === '0' ? $t('pages_user_127') : $t('pages_user_129')) }"
                style="
                  overflow: hidden;
                  flex: 1;
                "
              >
                <upload-auto
                  :ref="getRef"
                  v-model:file-content="form[index]['frontPath']"
                  accept=".jpg,.jpeg,.png,.pdf,.bmp"
                  type="INVITE"
                  list-type="picture"
                  :name-index="'uploadFRef' + index"
                  :max-count="1"
                />
              </a-form-item>
              <a-form-item
                v-if="item.originCustomertype === '0'"
                :name="[index, 'backPath']"
                :label="$t('pages_user_128')"
                :rules="{ required: true, message: $t('common_text_007') + $t('pages_user_128') }"
                style="
                  margin-left: 24px;
                  overflow: hidden;
                  flex: 1;
                "
              >
                <upload-auto
                  :ref="getRef"
                  v-model:file-content="form[index]['backPath']"
                  accept=".jpg,.jpeg,.png,.pdf,.bmp"
                  type="INVITE"
                  list-type="picture"
                  :name-index="'uploadBRef' + index"
                  :max-count="1"
                />
              </a-form-item>
            </div>
          </template>
          <template v-else>
            <a-divider orientation="right">{{ getTitle(item.type) }}</a-divider>
            <a-alert
              v-if="item.failReason"
              :message="item.failReason"
              style="margin-bottom: 24px"
              type="error"
              banner
            />
            <a-form-item
              :name="[index, 'frontPath']"
              :rules="{ required: true, message: $t('common_text_007') + getName(item.type) }"
              :label="getName(item.type)"
            >
              <div style="margin-bottom: 24px">
                <span>{{ $t('pages_merchant_035') }}</span>
                <a
                  :href="require(`/src/assets/files/${
                    item.type === '02' ? 'yingYeZhiZhao' :
                    item.type === '07' ? 'shangYeDJZ' : 'zhuCeZhengMing'
                  }.png`)"
                  target="_blank"
                >{{ $t('pages_merchant_046') }}</a>
                <span>{{ $t('pages_merchant_047') }}{{ getName(item.type) }}{{ $t('pages_merchant_048') }}</span>
              </div>
              <upload-auto
                :ref="getRef"
                v-model:file-content="form[index]['frontPath']"
                accept=".jpg,.jpeg,.png,.pdf,.bmp"
                type="INVITE"
                list-type="picture"
                :name-index="'uploadFRef' + index"
                :explain="$t('pages_merchant_032')"
                :max-count="5"
              />
            </a-form-item>
          </template>
        </div>
        <a-form-item
          style="
            text-align: right;
            margin-top: 48px;
          "
        >
          <a-button
            type="primary"
            ghost
            shape="round"
            style="min-width: 138px"
            @click="$router.back(-1)"
          >{{ $t('common_text_003') }}</a-button>
          <a-button
            :loading="loading"
            html-type="submit"
            type="primary"
            shape="round"
            style="
              margin-left: 18px;
              min-width: 138px;
            "
          >{{ $t('common_text_002') }}</a-button>
        </a-form-item>
      </a-form>
    </div>
  </div>
</template>

<script>
import HeaderNav from '@/components/header-nav'
import UploadAuto from '@/components/upload-auto'
import { reactive, toRefs, nextTick } from 'vue'
import { useRouter } from 'vue-router'
import { message } from 'ant-design-vue'
import { _user } from '@/api'
import i18n from '@/locale'
export default {
  name: 'CertUpdate',
  components: {
    'header-nav': HeaderNav,
    'upload-auto': UploadAuto
  },
  setup () {
    const router = useRouter()

    const state = reactive({
      loading: false,
      viewMap: {},
      form: [],
      certListN: []
    })

    const failed = () => {
      message.error(i18n.global.t('common_text_030'))
    }

    const finish = () => {
      state.loading = true
      const certificateRespList = state.form.map(item => {
        const idpath = []
        if (item.frontPath) idpath.push(...item.frontPath.split(','))
        if (item.backPath) idpath.push(item.backPath)
        return Object.assign({}, item, {
          idpath
        })
      }).concat(state.certListN)
      _user.updateCertificateExpired({ certificateRespList })
        .then(res => {
          state.loading = false
          if (res.data.succ) {
            message.success(i18n.global.t('common_text_064'))
            router.back(-1)
          } else {
            message.error(res.data.resMsg)
          }
        })
    }

    const icClick = (index) => {
      state.viewMap[index] = !state.viewMap[index]
    }

    const getRef = (el) => {
      if (el) state[el.nameIndex] = el
    }

    const getTitle = (type) => {
      if (type === '06') return i18n.global.t('pages_user_123')
      if (type === '04') return i18n.global.t('pages_user_130')
      if (type === '03') return i18n.global.t('pages_user_131')
      if (type === '05') return i18n.global.t('pages_user_132')
      return i18n.global.t('pages_user_133')
    }

    const getName = (type) => {
      if (type === '02') return i18n.global.t('pages_merchant_007')
      if (type === '07') return i18n.global.t('pages_merchant_016')
      return i18n.global.t('pages_merchant_017')
    }

    const loadExpired = async () => {
      const res = await _user.queryExpiredInformation()
      if (res.data.succ) {
        const { certificateRespList } = res.data.data
        state.form = certificateRespList.filter(item => item.isRight !== '1').map(item => {
          const num = item.originNumber.length - 2
          const reg = new RegExp(`(\\d{1})\\d{${num}}(\\d{1})`)
          return Object.assign({}, item, {
            originNumberText: item.originNumber.replace(reg, '$1****$2'),
            originCustomertypeText: item.originCustomertype === '0' ? i18n.global.t('pages_merchant_073') : i18n.global.t('pages_user_129')
          })
        })
        state.certListN = certificateRespList.filter(item => item.isRight === '1')
        nextTick(() => {
          loadUrls()
        })
      }
    }

    const loadUrls = async () => {
      for (let index = 0; index < state.form.length; index++) {
        const item = state.form[index]
        if (item.idpath) {
          if (item.idpath[0] && state[`uploadFRef${index}`]) {
            if (['01', '02', '07'].includes(item.type)) {
              await state[`uploadFRef${index}`].fileListRefresh(item.idpath)
              item.frontPath = item.idpath.join(',')
            } else {
              await state[`uploadFRef${index}`].fileListRefresh([item.idpath[0]])
              item.frontPath = item.idpath[0]
            }
          }
          if (!['01', '02', '07'].includes(item.type) && item.idpath[1] && state[`uploadBRef${index}`]) {
            await state[`uploadBRef${index}`].fileListRefresh([item.idpath[1]])
            item.backPath = item.idpath[1]
          }
        }
      }
      state.form = [...state.form]
    }

    const init = () => {
      loadExpired()
    }

    init()

    return {
      failed,
      finish,
      icClick,
      getRef,
      getTitle,
      getName,
      ...toRefs(state)
    }
  }
}
</script>

<style lang="less" scoped>
#certUpdate {
  .common-card-body {
    padding: 24px 36px;
    text-align: left;
    .cert-update-form {
      .upload-area {
        display: flex;
        margin-top: 12px;
      }
      .icon-area {
        margin-left: 5px;
        color: @main-color;
        cursor: pointer;
      }
    }
    .cert-update-form:deep(.ant-form-item-label) {
      font-weight: 600;
    }
  }
}
</style>
